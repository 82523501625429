
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { logout } from '@/api';

const Logout = defineComponent({
  setup() {
    const router = useRouter();

    onMounted(async () => {
      await logout();
      router.push({ name: 'Login' });
    });
  },
});

export default Logout;
