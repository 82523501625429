
import {
  computed, defineComponent, reactive, ref,
} from 'vue';

import CommonProvinceSelector from '@/components/LocationAddress/CommonProvinceSelector.vue';
import CommonDistrictSelector from '@/components/LocationAddress/CommonDistrictSelector.vue';

import {
  getVendors, updateVendor,
} from '@/api';
import vendorTableOption from './vendorTableOption';

const List = defineComponent({
  components: {
    CommonProvinceSelector,
    CommonDistrictSelector,
  },
  setup() {
    const address = reactive({
      provinces: [],
      districts: [],
    });

    const refetchQuery = ref(false);

    const computedProvinceIds = computed(() => address.provinces.map((elm: any) => elm.id));

    async function toggleActivateVendor(payload: any) {
      await updateVendor(payload);
      refetchQuery.value = true;
    }

    return {
      getVendors,
      vendorTableOption,
      refetchQuery,

      address,
      computedProvinceIds,

      toggleActivateVendor,
    };
  },
});

export default List;
