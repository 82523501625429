
import { defineComponent } from 'vue';

import CommonFormWithVendorEditor from '@/components/Vendor/CommonFormWithVendorEditor.vue';

const New = defineComponent({
  components: {
    CommonFormWithVendorEditor,
  },
  // setup() {},
});

export default New;
