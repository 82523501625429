import { snakeCase } from 'lodash';

export function sortParamToQuery(sortParam: { field: string; order: string }) {
  if (!sortParam) return '';
  return `&sort=${sortParam.order === 'DESC' ? '-' : ''}${sortParam.field}`;
}

export function sortParamToQueryCompatibleWithTable(sortParams: { field: string; order: string }[]): string {
  let result = '';
  if (sortParams.length === 0 || sortParams[0].field === undefined) return '';
  result = `${result}&sort=`;
  sortParams.forEach((sortParam: { field: string; order: string }, index: number) => {
    if (index !== 0) result = `${result},`;
    result = `${result}${sortParam.order === 'DESC' ? '-' : ''}${sortParam.field}`;
  });
  return result;
}

export function filterValueToQuery(filter: any) {
  if (!filter) return '';
  let result = '';
  Object.entries(filter).forEach(([key, value]: any[]) => {
    if (key === 'orderBy') result += sortParamToQuery(value);
    else if (Array.isArray(filter[key])) {
      // In case of recent variable is type of Array, then loop through whole array element;
      // eslint-disable-next-line
      filter[key].forEach((elm: any) => {
        // If inside of Array is object, call nested;
        if (typeof elm === 'object' && !(Array.isArray(elm))) result = filterValueToQuery(elm);
        // if not just added;
        result = `${result}&${key}[]=${elm}`;
      });
    } else if (value !== null && value !== '' && value !== undefined) result = `${result}&${key}=${value}`;
  });
  return result;
}

export function camelObjectToSnakeObject(object: any, except: string[] = []) {
  const output = {};
  Object.keys(object).forEach((key: string) => {
    let newKey = key;
    if (!((object as any)[newKey] || (object as any)[newKey] === false) && (object as any)[newKey] !== 0) return;
    if (!(except.indexOf(key) > -1)) newKey = snakeCase(key);

    if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
      // To check is recent variable is type of object and not array
      (output as any)[newKey] = camelObjectToSnakeObject(object[key], except);
      // ---
    } else if (Array.isArray(object[key])) {
      // In case of recent variable is type of Array, then loop through whole array element;
      (output as any)[newKey] = object[key].map((elm: any) => {
        // If inside of Array is object, call nested;
        if (typeof elm === 'object') return camelObjectToSnakeObject(elm, except);
        // if not just return;
        return elm;
      });
    } else {
      (output as any)[newKey] = object[key];
    }
  });
  return output;
}
