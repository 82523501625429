import { useFormat } from 'vivat-next';

function filterValueToQuery(filter: any) {
  if (!filter) return '';
  let result = '';
  Object.entries(filter).forEach(([key, value]: any[]) => {
    if (value !== null && value !== '' && value !== undefined) result = `${result}&${key}=${value}`;
  });
  return result;
}

function getPDFReport(report: string, key: number | string) {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${localStorage.getItem('tms-admin-apollo-token')}`);

  const file = `${process.env.VUE_APP_BACKEND_HTTP}/api/report/${report}/${key}`;

  fetch(file, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = `[${useFormat.date().computerShortDate()}]-TMS-Report-${report}-${key}`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
}

function getCSVReport(route: string, filter: any) {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${localStorage.getItem('tms-admin-apollo-token')}`);

  const file = `${process.env.VUE_APP_BACKEND_HTTP}/api/reports/${route}?${filterValueToQuery(filter)}`;

  fetch(file, { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = `[${useFormat.date().computerShortDate()}]-TMS-Report-${route}`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
}

export default function useDownloadReport() {
  return {
    getPDFReport,
    getCSVReport,
  };
}
