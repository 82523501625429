import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import vivatRouter from './useRouter';

export default function useSyncQueryVariable(variableName: string, initialValue?: any | undefined, usePush = false) {
  // query param is the source of truth

  const { QUERY_SYNC_PREFIX } = vivatRouter();
  const route = useRoute();
  const router = useRouter();

  function setQuery(name: string, value: any) {
    let parsedValue = value;
    if (parsedValue === '') parsedValue = undefined;
    setTimeout(() => {
      if (usePush) {
        router.push({
          query: {
            ...route.query,
            [name]: parsedValue,
          },
        });
      } else {
        router.replace({
          query: {
            ...route.query,
            [name]: parsedValue,
          },
        });
      }
    }, 1);
  }

  const result = computed({
    get: () => {
      if (Object.keys(route.query).length === 0 || !((`${QUERY_SYNC_PREFIX}${variableName}` as string) in route.query)) return undefined;
      return route.query[`${QUERY_SYNC_PREFIX}${variableName}`];
    },
    set: (val) => {
      setQuery(`${QUERY_SYNC_PREFIX}${variableName}`, val);
    },
  });

  if (!result.value && initialValue) result.value = initialValue;

  return result;
}
