import useAuth from '@/use/useAuth';
import useAxios from '@/use/useAxios';

import address from '@/api/address';
import role from '@/api/role';
import user from '@/api/user';
import vendor from '@/api/vendor';

const {
  axios, setAuthorization, removeAuthorization,
} = useAxios();
const { getMe } = useAuth();

export const {
  getProvinces,
  getDistricts,
  getSubDistricts,
} = address(axios);

export const {
  createRole,
  updateRole,
  getRole,
  getRoles,

  getPermissions,
} = role(axios);

export const {
  createUser,
  updateUser,
  getUser,
  getUsers,
} = user(axios);

export const {
  createVendor,
  getVendor,
  getVendors,
  updateVendor,
  deleteVendor,
} = vendor(axios);

// ===========================================

// ===========================================

export async function login(
  email: string,
  password: string,
) {
  try {
    const result = await axios.post('/auth/login', {
      email,
      password,
    });
    const { access_token: accessToken } = result.data;
    if (accessToken !== '') {
      await setAuthorization(accessToken);
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

export async function logout() {
  try {
    const result = await axios.post('/auth/logout');
    if (result.status === 200) {
      removeAuthorization();
      return true;
    }
  } catch {
    return false;
  }
  return false;
}
