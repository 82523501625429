
import { defineComponent } from 'vue';

import PageColumnWithUserEditor from '@/components/User/PageColumnWithUserEditor.vue';

const Edit = defineComponent({
  props: {
    initRole: {},
  },
  components: {
    PageColumnWithUserEditor,
  },
  // setup() {},
});

export default Edit;
