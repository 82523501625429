
import {
  defineComponent, reactive, computed, ref, onMounted,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useFormat } from 'vivat-next';

import user from '@/views/setting/user/user';
import useAuth from '@/use/useAuth';

import CommonModalWithPreset from '@/components/CommonModalWithPreset.vue';
import CommonFormWithRoleEditor from '@/components/User/CommonFormWithRoleEditor.vue';

const List = defineComponent({
  components: {
    CommonModalWithPreset,
    CommonFormWithRoleEditor,
  },
  props: {
    role: {},
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { getMe, hasPermission } = useAuth();
    const me = ref();
    onMounted(async () => {
      me.value = getMe();
    });

    const formCreate = reactive({
      name: '',
    });

    function deleteRoleAction(roleId: string) {
      console.log('delete role');
    }

    // Search filter
    const search = reactive({
      text: '',
    });

    // =================== DELETE FUNCTION ======================
    // Delete dialog
    const deleteUserModal = ref(null);
    // Delete Mutation variables
    const deleteUserVariable = reactive({ id: -1 });
    // ==== Delete Mutation ==== //

    function openDeleteUserModal(item: any) {
      (deleteUserModal.value as any).compose({
        id: parseInt(item.id, 10),
        action: 'ลบ',
        entity: 'ผู้ใช้งาน',
        body: `เป็นการลบ<br>${item.name}<br>จากระบบโดยถาวร<br>ไม่สามารถย้อนคืนได้`,
      });
    }

    // ==== Trigger Delete User mutation
    function deleteUserAction(id: any) {
      deleteUserVariable.id = id;
    }

    // Active Deactive Mutation
    const toggleVariables = reactive({
      id: '',
      status: true,
    });

    function setActiveStatus(id: any, status: boolean) {
      toggleVariables.id = id;
      toggleVariables.status = status;
    }

    // ==== Use to search User ==== //
    const usersListVariables = computed(() => {
      const vars: {[key: string]: any} = {
        searchName: undefined,
        filterRoleId: route.params.roleId,
      };
      if (search.text) {
        vars.searchName = `%${search.text}%`;
      }
      return vars;
    });

    // ==== Use Format ==== //
    function useDateTimeFormat(date: any) {
      if (!date) return '';
      return `${useFormat.date(date).displayShortThaiDate()} เวลา ${date.substring(11, 16)}`;
    }

    return {
      me,
      hasPermission,
      deleteRoleAction,
      formCreate,
      userTableOption: user,

      getUsersResult: (data: any) => data.users,
      search,
      // Delete User funtion
      deleteUserModal,
      openDeleteUserModal,
      // setDeleteUserVariable,
      deleteUserAction,
      // User list gql
      usersListVariables,

      setActiveStatus,
      useDateTimeFormat,
    };
  },
});

export default List;
