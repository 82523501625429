import { useFormat } from 'vivat-next';

export default {
  types: {
    _primaryControls: {
      name: 'เมนู',
      width: '3rem',
      class: 'text-center',
    },
    _increment: {
      name: 'ลำดับ',
      width: '3rem',
      class: 'text-center',
    },
    code: {
      name: 'รหัส',
      width: '8rem',
      class: 'text-left',
    },
    name: {
      name: 'ชื่อ',
      width: '1fr',
      class: 'text-left',
    },
    created_at: {
      name: 'วันที่สร้าง',
      width: '10rem',
      class: 'text-left',
      filter: (val: any) => useFormat.date(val).displayShortThaiDateTime(),
    },
    updated_at: {
      name: 'แก้ไขล่าสุด',
      width: '10rem',
      class: 'text-left',
      filter: (val: any) => useFormat.date(val).displayShortThaiDateTime(),
    },
    _activeControls: {
      name: 'เปิดใช้งาน',
      width: '6rem',
      class: 'text-center',
    },
  },
};
