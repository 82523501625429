
import { defineComponent, ref } from 'vue';
import useRouter from '@/use/useRouter';

import useAuth from '@/use/useAuth';

import CommonModalWithPreset from '@/components/CommonModalWithPreset.vue';

const AppFastCloud = defineComponent({
  components: {
    CommonModalWithPreset,
  },
  setup() {
    // const { me, isLoggedIn, hasPermission } = useAuth();
    const currentExpand = ref('');

    const { route } = useRouter();

    return {
      route,
      currentExpand,
      // me,
      // isLoggedIn,
      // hasPermission,
    };
  },
});

export default AppFastCloud;
