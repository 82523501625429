// import ReportDelivery from '@/views/report/Delivery.vue';
import ReportCSVFailedDelivery from '@/views/report/csv/FailedDeliveryCsvReport.vue';
import ReportCSVSuccessfulDelivery from '@/views/report/csv/SuccessfulDeliveriesCsvReport.vue';
import ReportCSVOutstandingBillDelivery from '@/views/report/csv/OutstandingBillCsvReport.vue';
import ReportCSVDriverIncome from '@/views/report/csv/DriverIncomeCsvReport.vue';
import ReportCSVDriverFuelConsumption from '@/views/report/csv/DriverFuelConsumptionReport.vue';
import ReportCSVVehicleMaintenanceCost from '@/views/report/csv/VehicleMaintenanceCostReport.vue';
import ReportCSVPackageIncome from '@/views/report/csv/PackageIncomeReport.vue';
import ReportCSVVehicleWorkDayRatio from '@/views/report/csv/VehicleWorkDayRatioReport.vue';
import ReportCSVAreaIncomeReport from '@/views/report/csv/AreaIncomeReport.vue';
import ReportCSVUnderWeightedVehicleReport from '@/views/report/csv/UnderWeightedVehicleReport.vue';
import ReportCSVDeliveryDuration from '@/views/report/csv/DeliveryDuration.vue';
import ReportCSVAreaDeliveryCost from '@/views/report/csv/AreaDeliveryCost.vue';
import ReportCSVAreaGpReport from '@/views/report/csv/AreaGpReport.vue';
import ReportCSVAreaPercentTReport from '@/views/report/csv/AreaPercentTReport.vue';
import ReportCSVPackageCostReport from '@/views/report/csv/PackageCost.vue';

function getComponentFile(name: string) {
  if (name === 'ReportCSVFailedDelivery') return ReportCSVFailedDelivery;
  if (name === 'ReportCSVSuccessfulDelivery') return ReportCSVSuccessfulDelivery;
  if (name === 'ReportCSVOutstandingBillDelivery') return ReportCSVOutstandingBillDelivery;
  if (name === 'ReportCSVDriverIncome') return ReportCSVDriverIncome;
  if (name === 'ReportCSVDriverFuelConsumption') return ReportCSVDriverFuelConsumption;
  if (name === 'ReportCSVVehicleMaintenanceCost') return ReportCSVVehicleMaintenanceCost;
  if (name === 'ReportCSVPackageIncome') return ReportCSVPackageIncome;
  if (name === 'ReportCSVVehicleWorkDayRatio') return ReportCSVVehicleWorkDayRatio;
  if (name === 'ReportCSVAreaIncomeReport') return ReportCSVAreaIncomeReport;
  if (name === 'ReportCSVUnderWeightedVehicleReport') return ReportCSVUnderWeightedVehicleReport;
  if (name === 'ReportCSVDeliveryDuration') return ReportCSVDeliveryDuration;
  if (name === 'ReportCSVAreaDeliveryCost') return ReportCSVAreaDeliveryCost;
  if (name === 'ReportCSVAreaGpReport') return ReportCSVAreaGpReport;
  if (name === 'ReportCSVAreaPercentTReport') return ReportCSVAreaPercentTReport;
  if (name === 'ReportCSVPackageCostReport') return ReportCSVPackageCostReport;

  return null;
}

export const moduleList = [
  // {
  //   path: 'delivery',
  //   name: 'ReportDelivery',
  //   display: 'ใบส่งสินค้า',
  // },
  {
    path: 'outstanding-bill-delivery',
    name: 'ReportCSVOutstandingBillDelivery',
    display: 'ค้างส่ง',
  },
  {
    path: 'success-delivery',
    name: 'ReportCSVSuccessfulDelivery',
    display: 'ส่งสำเร็จ',
  },
  {
    path: 'failed-delivery',
    name: 'ReportCSVFailedDelivery',
    display: 'ส่งไม่สำเร็จ',
  },
  {
    path: 'driver-income',
    name: 'ReportCSVDriverIncome',
    display: 'รายได้คนขับ',
  },
  {
    path: 'driver-fuel-consumption',
    name: 'ReportCSVDriverFuelConsumption',
    display: 'อัตราการเผาผลาญน้ำมัน',
  },
  {
    path: 'vehicle-maintenance-cost',
    name: 'ReportCSVVehicleMaintenanceCost',
    display: 'ค่าบำรุงรักษารถขนส่ง',
  },
  {
    path: 'package-income',
    name: 'ReportCSVPackageIncome',
    display: 'รายได้ตามหน่วยขนส่ง',
  },
  {
    path: 'vehicle-workday-ratio',
    name: 'ReportCSVVehicleWorkDayRatio',
    display: 'อัตราการวิ่งรถขนส่ง',
  },
  {
    path: 'area-income-report',
    name: 'ReportCSVAreaIncomeReport',
    display: 'รายได้ขนส่ง ตามพื้นที่จัดส่ง',
  },
  {
    path: 'under-weight-vehicle',
    name: 'ReportCSVUnderWeightedVehicleReport',
    display: 'รถบรรทุกที่ไม่ผ่านขั้นต่ำ',
  },
  {
    path: 'delivery-duration',
    name: 'ReportCSVDeliveryDuration',
    display: 'ระยะเวลาในการจัดส่ง (วัน)',
  },
  {
    path: 'area-delivery-cost',
    name: 'ReportCSVAreaDeliveryCost',
    display: 'ค่าจัดส่ง ตามพื้นที่จัดส่ง',
  },
  {
    path: 'package-delivery-cost',
    name: 'ReportCSVPackageCostReport',
    display: 'ค่าจัดส่ง ตามหน่วยขนส่ง',
  },
  {
    path: 'area-gp',
    name: 'ReportCSVAreaGpReport',
    display: '%GP',
  },
  {
    path: 'percent-t',
    name: 'ReportCSVAreaPercentTReport',
    display: '%T',
  },
];

export default moduleList.map((module: any) => ({
  path: module.path,
  name: module.name,
  component: getComponentFile(module.name),
})) as any;
