import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';

import AppFastCloud from '@/views/AppFastCloud.vue';
import Dashboard from '@/views/Dashboard.vue';

import VendorIndex from '@/views/vendor/Index.vue';
import VendorList from '@/views/vendor/List.vue';
import VendorCreate from '@/views/vendor/New.vue';
import VendorEdit from '@/views/vendor/Edit.vue';

// import AnalyticsIndex from '@/views/report/analytic/Index.vue';
// import AnalyticsRouter from '@/views/report/analytic/analytic-router';

import ReportIndex from '@/views/report/Index.vue';
import ReportList from '@/views/report/List.vue';
import ReportCSVRouter from '@/views/report/csv/csv-report-router';

import SettingIndex from '@/views/setting/Index.vue';
import SettingUserIndex from '@/views/setting/user/Index.vue';
import SettingUserNew from '@/views/setting/user/New.vue';
import SettingUserEdit from '@/views/setting/user/Edit.vue';
import SettingUserList from '@/views/setting/user/List.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/app',
    // beforeEnter: guardAuth, // Check only root, no need to check children
    name: 'App',
    component: AppFastCloud,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'vendor',
        name: 'VendorIndex',
        component: VendorIndex,
        children: [
          {
            path: '',
            name: 'VendorList',
            component: VendorList,
          },
          {
            path: 'new',
            name: 'VendorCreate',
            component: VendorCreate,
          },
          {
            path: 'edit/:id',
            name: 'VendorEdit',
            component: VendorEdit,
          },
        ],
      },
      {
        path: 'report',
        component: ReportIndex,
        children: [
          // {
          //   path: 'report/analytics',
          //   component: AnalyticsIndex,
          //   name: 'AnalyticsIndex',
          //   children: AnalyticsRouter,
          // },
          {
            path: 'report-list',
            name: 'ReportList',
            component: ReportList,
            children: ReportCSVRouter,
          },
        ],
      },
      {
        path: 'setting',
        name: 'SettingIndex',
        component: SettingIndex,
        children: [
          {
            path: 'user',
            component: SettingUserIndex,
            children: [
              {
                path: '',
                name: 'SettingUserIndex',
                component: SettingUserList,
              },
              {
                path: 'edit/:id',
                name: 'SettingUserEdit',
                component: SettingUserEdit,
              },
              {
                path: ':roleId/create',
                name: 'SettingUserNew',
                component: SettingUserNew,
              },
              {
                path: ':roleId',
                name: 'SettingUserList',
                component: SettingUserList,
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
