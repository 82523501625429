
import { defineComponent, computed } from 'vue';

import {
  getProvinces,
} from '@/api';

const CommonProvinceSelector = defineComponent({
  props: {
    label: {
      type: String,
      default() { return 'จังหวัด'; },
    },
    modelValue: {
      type: [Object],
      default() { return null; },
    },
    level: {},
    showLabel: { default: false },
    rules: { default: '' },
    showAsEnglish: { default: false },
    multiple: { default: false },
  },
  setup(props: any) {
    const getProvincesResult = (data: any) => data;

    return {
      getProvinces,
      getProvincesResult,
    };
  },
});

export default CommonProvinceSelector;
