
import { defineComponent, computed } from 'vue';

import {
  getDistricts,
} from '@/api';

const CommonDistrictSelector = defineComponent({
  props: {
    level: {},
    label: {
      type: String,
      default() { return 'อำเภอ'; },
    },
    modelValue: {
      type: [Object],
      default() { return null; },
    },
    showLabel: { default: false },
    rules: { default: '' },
    provinceId: { default: -1 },
    showAsEnglish: { default: false },
    multiple: { default: false },
    provinceIds: { default: [] },
  },
  setup(props: any) {
    const getDistrictsResult = (data: any) => data;

    const value = computed(() => props.modelValue);

    const computedProvinceIds = computed(() => {
      const arr = props.provinceIds;
      if (props.provinceId > -1) arr.push(props.provinceId);
      return arr;
    });

    return {
      getDistricts,
      getDistrictsResult,
      value,

      computedProvinceIds,
    };
  },
});

export default CommonDistrictSelector;
