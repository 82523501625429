
import {
  defineComponent, reactive, ref, onMounted, computed,
} from 'vue';
import { useRouter } from 'vue-router';

import {
  login,
} from '@/api/index';

import CommonModalWithPreset from '@/components/CommonModalWithPreset.vue';

const Login = defineComponent({
  components: {
    CommonModalWithPreset,
  },
  setup() {
    const modal = ref(null);
    const router = useRouter();

    const loginFormInput = reactive({
      email: '',
      password: '',
      call: async () => {
        const success = await login(
          loginFormInput.email,
          loginFormInput.password,
        );
        if (success) {
          router.push({
            name: 'Dashboard',
          });
        } else {
          (modal.value as any).compose();
        }
      },
    });

    onMounted(() => {
      const emailFieldInput = document.querySelector('#common-input-email');
      if (emailFieldInput) (emailFieldInput as HTMLElement).focus();
    });

    return {
      modal,
      loginFormInput,

      buildNote: (window as any).BUILD_NOTE,
    };
  },
});

export default Login;
