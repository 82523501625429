import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonModalWithPreset = _resolveComponent("CommonModalWithPreset")!
  const _component_AdvancePartOfSidebar = _resolveComponent("AdvancePartOfSidebar")!
  const _component_PanelSidebar = _resolveComponent("PanelSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageApp = _resolveComponent("PageApp")!

  return (_openBlock(), _createBlock(_component_PageApp, { class: "app-fast-cloud" }, {
    sidebar: _withCtx(() => [
      _createVNode(_component_PanelSidebar, {
        home: { name: 'Dashboard' },
        logo: "/img/logo_fast.svg"
      }, {
        header: _withCtx(() => []),
        footer: _withCtx(() => [
          _createVNode(_component_AdvancePartOfSidebar, {
            icon: "img/icons/icon_fast.svg#svgView(viewBox(24,72,24,21))",
            iconClass: "w-4 h-4",
            name: "ออกจากระบบ",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$refs.logoutConfirmModal.compose({ action: 'ออกจากระบบ' })))
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_AdvancePartOfSidebar, {
            icon: "img/icons/icon_fast.svg#svgView(viewBox(409,24,24,24))",
            iconClass: "w-12 h-10",
            name: "TMS",
            modelValue: _ctx.currentExpand,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentExpand) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AdvancePartOfSidebar, {
                to: { name: 'VendorCreate' },
                name: "เพิ่ม TMS",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentExpand = ''))
              }),
              _createVNode(_component_AdvancePartOfSidebar, {
                to: { name: 'VendorList' },
                name: "รายชื่อ TMS",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentExpand = ''))
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_AdvancePartOfSidebar, {
            icon: "img/icons/006.svg",
            name: "รายงาน",
            modelValue: _ctx.currentExpand,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentExpand) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AdvancePartOfSidebar, {
                to: { name: 'ReportList' },
                name: "เอกสาร",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.currentExpand = ''))
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CommonModalWithPreset, {
        ref: "logoutConfirmModal",
        type: "confirm-logout",
        onDone: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Logout' })))
      }, null, 512),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}