
import {
  defineComponent, watchEffect, reactive, watch, ref, onMounted, Ref,
} from 'vue';
import { useSelectable } from 'vivat-next';
import { useRoute } from 'vue-router';

import {
  getRole,
  getPermissions,
  updateRole,
} from '@/api';
import useAuth from '@/use/useAuth';

import CommonModalWithPreset from '@/components/CommonModalWithPreset.vue';

const CommonFormWithRoleEditor = defineComponent({
  components: {
    CommonModalWithPreset,
  },
  props: {
    module: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const doneModal = ref(null);

    const form = reactive({
      name: '',
      permissions: [],
      call: async () => {
        await updateRole(route.params.roleId as any, {
          name: form.name,
          // eslint-disable-next-line
          permissions: selectedList.value,
        });
        (doneModal.value as any).compose({ action: 'บันทึก', entity: 'กลุ่มผู้ใช้' });
        emit('done');
      },
      state: {
        value: 'READY',
      },
    });

    const {
      selectedItems: selectedList,
      onSelect: onSelectList,
      isSelected: isSelectedList,
    } = useSelectable(form.permissions, (elm: any) => elm.id);
    watchEffect(() => {
      selectedList.value = form.permissions;
    });

    const permissions: Ref<any> = ref(null);
    async function getPermissionsInfo() {
      const perms = await getPermissions();
      // const permsByModule = props.module.map((module: any) => ({ module, permissions: [] })) as {
      //   module: string;
      //   permissions: {
      //     id: number;
      //     module: string;
      //     moduleDisplayName: string;
      //     name: string;
      //     displayName: string;
      //   }[];
      // }[];
      // perms.forEach((perm: any) => {
      //   if (perm.displayName === '') return; // in case of hidden perm: like wildcard
      //   const permByModuleEntries = permsByModule.filter((pbm: any) => pbm.module === perm.module);
      //   if (permByModuleEntries.length === 0) {
      //     permsByModule.push({
      //       module: perm.module,
      //       permissions: [],
      //     });
      //   }
      //   const permIndex = permsByModule.findIndex((pbm: any) => pbm.module === perm.module);
      //   permsByModule[permIndex].permissions.push(perm as any);
      // });
      // permissions.value = permsByModule;
    }

    function onSelect(items: any, setTo: any) {
      onSelectList(items, setTo);
      form.permissions = selectedList.value;
    }

    async function getRoleInfo() {
      const role = ((await getRole(route.params.roleId as any)).data as any);
      form.name = role.name;
      form.permissions = role.permissions.map((elm: any) => elm.id);
    }

    watch(() => route.params.roleId, async (newVal, oldVal) => {
      if (newVal !== oldVal) await getRoleInfo();
    });

    onMounted(async () => {
      await getPermissionsInfo();
      await getRoleInfo();
    });

    return {
      doneModal,
      form,
      permissions,

      selectedList,
      onSelect,
      isSelectedList,
    };
  },
});

export default CommonFormWithRoleEditor;
