import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartOfListItem = _resolveComponent("PartOfListItem")!
  const _component_PanelList = _resolveComponent("PanelList")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_LayoutFillPageWithEmptyState = _resolveComponent("LayoutFillPageWithEmptyState")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AdvancePageColumn = _resolveComponent("AdvancePageColumn")!

  return (_openBlock(), _createBlock(_component_AdvancePageColumn, {
    class: "list",
    paddingMainContents: false
  }, {
    "left-panel": _withCtx(() => [
      _createVNode(_component_Panel, {
        class: "min-h-full px-3",
        title: "รายงาน"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PanelList, {
            class: "mt-3 common-panel-list-with-role-display",
            items: _ctx.moduleList
          }, {
            default: _withCtx((panelList) => [
              _createVNode(_component_PartOfListItem, {
                title: panelList.item.display,
                active: _ctx.$route.name == panelList.item.name,
                onClick: ($event: any) => (_ctx.$router.push({ name: panelList.item.name }))
              }, null, 8, ["title", "active", "onClick"])
            ]),
            _: 1
          }, 8, ["items"])
        ]),
        _: 1
      })
    ]),
    "empty-state-panel": _withCtx(() => [
      _createVNode(_component_LayoutFillPageWithEmptyState, { forName: "ประเภทรายงาน" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}