
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from 'vue';
import _ from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import {
  createUser, updateUser, getUser,
} from '@/api/index';
import useAuth from '@/use/useAuth';
import CommonModalWithPreset from '@/components/CommonModalWithPreset.vue';

const UserEditor = defineComponent({
  components: {
    CommonModalWithPreset,
  },
  props: {
    id: { type: [Number, String], default: -1 },
    edit: { default: false },
  },
  setup(props: any, { emit }: any) {
    const router = useRouter();
    const route = useRoute();
    const { hasPermission } = useAuth();

    const confirmModal = ref(null);
    const errorModal = ref(null);
    const form: { [key: string]: any } = reactive({
      name: '',
      email: '',
      oldEmail: '',
      employeeCode: '',
      password: '',
      confirmPassword: '',
      call: async () => {
        let result = null;
        try {
          if (props.edit) {
            result = await updateUser(route.params.id as any, {
              name: form.name,
              email: form.oldEmail === form.email ? undefined : form.email,
              password: form.password,
              employeeCode: form.employeeCode,
            });
          } else {
            result = await createUser({
              name: form.name,
              email: form.email,
              password: form.password,
              employeeCode: form.employeeCode,
              initialRoleId: route.params.roleId as any,
              active: true,
            });
          }
        } catch (e) {
          //
        } finally {
          if (result) (confirmModal.value as any).compose({ action: 'บันทึก', entity: 'ข้อมูลเรียบร้อย' });
          else (errorModal.value as any).compose();
        }
      },
      state: {
        value: 'READY',
      },
    });
    onMounted(async () => {
      if (props.edit) {
        const result = (await getUser(route.params.id as any));
        // form.name = result.data.name;
        // form.email = result.data.email;
        // form.oldEmail = result.data.email;
        // form.employeeCode = result.data.employeeCode;
      }
    });

    return {
      form,
      confirmModal,
      errorModal,
    };
  },
});

export default UserEditor;
