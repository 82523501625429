
import {
  defineComponent, ref, watch, computed, onMounted,
} from 'vue';
import {
  useScreenWidth,
} from 'vivat-next';

import useSyncQueryVariable from '@/use/useSyncQueryVariable';

const PageColumn = defineComponent({
  props: {
    mobileLeftPanelAsSidebar: {
      type: String,
      default: '',
    },
    mobileRightPanelAsSidebar: {
      type: String,
      default: '',
    },
    leftInvert: {
      type: Boolean,
      default: true,
    },
    paddingMainContents: {
      type: Boolean,
      default: true,
    },
    scrollBarColor: {},
  },
  setup(props) {
    const { isMobile } = useScreenWidth();

    const isLeftSidebarActive = useSyncQueryVariable(`pageColumnLeft${Math.ceil(Math.random() * 100)}Sidebar`, undefined, true);
    const isRightSidebarActive = useSyncQueryVariable(`pageColumnRight${Math.ceil(Math.random() * 100)}Sidebar`, undefined, true);
    const retainScrollPosition = ref(0);
    const isHideHintSidebarText = ref(false);
    let hideHintTimeout: any;

    function showHint(ms = 3000) {
      if (hideHintTimeout) clearTimeout(hideHintTimeout);
      isHideHintSidebarText.value = false;
      hideHintTimeout = setTimeout(() => {
        isHideHintSidebarText.value = true;
      }, ms);
    }

    onMounted(() => {
      showHint();
    });

    function toggleLeftColumnAsSidebar() {
      if (!isLeftSidebarActive.value) { // from hidden to show
        retainScrollPosition.value = window.scrollY;
        isLeftSidebarActive.value = 'true';
        setTimeout(() => {
          window.scrollTo(window.scrollX, 0);
        }, 1);
      } else {
        showHint(4000);
        isLeftSidebarActive.value = undefined;
        setTimeout(() => {
          window.scrollTo(window.scrollX, retainScrollPosition.value);
        }, 1);
      }
    }
    function toggleRightColumnAsSidebar() {
      if (!isRightSidebarActive.value) { // from hidden to show
        retainScrollPosition.value = window.scrollY;
        isRightSidebarActive.value = 'true';
        setTimeout(() => {
          window.scrollTo(window.scrollX, 0);
        }, 1);
      } else {
        showHint(4000);
        isRightSidebarActive.value = undefined;
        setTimeout(() => {
          window.scrollTo(window.scrollX, retainScrollPosition.value);
        }, 1);
      }
    }

    watch(isMobile, () => {
      if (!isMobile.value && isLeftSidebarActive.value) toggleLeftColumnAsSidebar();
    });

    const computedMobileLeftPanelAsSidebarName = computed(() => props.mobileLeftPanelAsSidebar);
    const computedMobileLeftPanelAsSidebar = computed(() => props.mobileLeftPanelAsSidebar !== '');
    const computedMobileRightPanelAsSidebarName = computed(() => props.mobileRightPanelAsSidebar);
    const computedMobileRightPanelAsSidebar = computed(() => props.mobileRightPanelAsSidebar !== '');

    return {
      isMobile,
      toggleRightColumnAsSidebar,
      isLeftSidebarActive,
      isRightSidebarActive,
      isHideHintSidebarText,
      toggleLeftColumnAsSidebar,
      computedMobileLeftPanelAsSidebar,
      computedMobileLeftPanelAsSidebarName,
      computedMobileRightPanelAsSidebar,
      computedMobileRightPanelAsSidebarName,
    };
  },
});

export default PageColumn;
