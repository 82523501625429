
import { defineComponent, reactive } from 'vue';

import useDownloadReport from '@/use/useDownloadReport';

const AreaDeliveryCost = defineComponent({
  setup() {
    const { getCSVReport } = useDownloadReport();
    const filter = reactive({
      range: {
        from: undefined,
        to: undefined,
      },
    });

    return {
      filter,
      getCSVReport,
    };
  },
});

export default AreaDeliveryCost;
