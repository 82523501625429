import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!
  const _component_AdvancePageColumn = _resolveComponent("AdvancePageColumn")!

  return (_openBlock(), _createBlock(_component_AdvancePageColumn, { class: "dashboard" }, {
    default: _withCtx(() => [
      _createVNode(_component_Panel, null, {
        default: _withCtx(() => [
          _createVNode(_component_Panel, null, {
            default: _withCtx(() => [
              _createVNode(_component_Panel)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}