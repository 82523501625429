
import { defineComponent, reactive } from 'vue';
import { useFormat } from 'vivat-next';

import useDownloadReport from '@/use/useDownloadReport';

const NOW = useFormat.date().computerShortDate();

const PackageIncomeReport = defineComponent({
  setup() {
    const { getCSVReport } = useDownloadReport();
    const filter = reactive({
      range: {
        from: undefined,
        to: undefined,
      },
    });

    return {
      filter,
      getCSVReport,
    };
  },
});

export default PackageIncomeReport;
