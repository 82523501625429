import { AxiosInstance } from 'axios';

import { camelObjectToSnakeObject, filterValueToQuery } from '@/api/utils';

export default function vendor(axios: AxiosInstance) {
  async function createVendor(payload: any) {
    const response = await axios.post('/tms', camelObjectToSnakeObject(payload));
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.error('[api/vendor.ts] createVendor Error', e);
    }
    return false;
  }

  async function getVendor(id: number) {
    const response = await axios.get(`/tms/${id}`);
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.error('[api/vendor.ts] getVendors Error', e);
    }
    return false;
  }

  async function getVendors(payload: any) {
    const response = await axios.get(`/tms?${filterValueToQuery(payload)}`);
    try {
      if (response.status === 200) {
        return {
          data: {
            data: response.data.map((vendor: any) => ({ ...vendor, active: vendor.active === 1 })),
            from: 1,
            to: 1,
            last_page: 1,
            total: response.data.length,
            current_page: 1,
            per_page: response.data.length,
          },
          success: true,
        };
      }
    } catch (e) {
      console.error('[api/vendor.ts] getVendors Error', e);
    }
    return false;
  }

  async function updateVendor(payload: any) {
    const response = await axios.put('/tms', camelObjectToSnakeObject(payload));
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.error('[api/vendor.ts] updateVendor Error', e);
    }
    return false;
  }

  async function deleteVendor(payload: any) {
    // tms
    // districts
    // provinces
    // const response = await axios.delete('/tms/serviceArea', camelObjectToSnakeObject(payload));
    // try {
    //   if (response.status === 200) {
    //     return response.data;
    //   }
    // } catch (e) {
    //   console.error('[api/vendor.ts] createVendor Error', e);
    // }
    // return false;
  }

  return {
    createVendor,
    getVendor,
    getVendors,
    updateVendor,
    deleteVendor,
  };
}
