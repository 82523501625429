
import {
  defineComponent, onMounted, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';

import CommonModalWithPreset from '@/components/CommonModalWithPreset.vue';

import {
  createVendor,
  getVendor,
  updateVendor,
} from '@/api';

const CommonFormWithVendorEditor = defineComponent({
  components: {
    CommonModalWithPreset,
  },
  props: {
    edit: { default: undefined },
  },
  setup(props) {
    const confirmModal = ref(null);
    const errorModal = ref(null);

    const form = reactive({
      name: '',
      code: '',
      webhook: '',
      state: {
        value: 'READY',
      },
      call: async () => {
        let result = null;
        try {
          if (props.edit) {
            result = await updateVendor({
              id: props.edit,
              name: form.name,
              code: form.code,
              webhook: form.webhook || '-',
            });
          } else {
            result = await createVendor({
              name: form.name,
              code: form.code,
              webhook: form.webhook || '-',
            });
          }
        } catch (e) {
          //
        } finally {
          if (result) (confirmModal.value as any).compose({ action: 'บันทึก', entity: 'ข้อมูลเรียบร้อย' });
          else (errorModal.value as any).compose();
        }
      },
    });

    async function getVendorData() {
      if (props.edit) {
        const result = await getVendor(props.edit as any);
        if (result) {
          form.name = result.name;
          form.code = result.code;
          form.webhook = result.webhook;
        }
      }
    }

    onMounted(async () => {
      await getVendorData();
    });

    return {
      form,
    };
  },
});

export default CommonFormWithVendorEditor;
