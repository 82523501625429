import {
  reactive,
  watch,
  computed,
  ref,
} from 'vue';

import useAxios from '@/use/useAxios';

const { axios } = useAxios();
// let hasRan = false;

const me = reactive({
  id: -1,
  active: true,
  name: '',
  email: '',
  telephone: '',
  avatarPath: null,
  online: false,
  organization: '',
  checkedIn: 0,
  permissions: [''],
});

const isAttemptedCheck = ref(false);

// const warnAvatarFallback = (() => {
//   let isRan = false;
//   return () => {
//     if (!isRan) {
//       console.warn('[useAuth] Avatar path is implemented in incorrect format or it is not exist. Fallback to default cute Aya avatar!');
//       isRan = true;
//     }
//   };
// })();

export const callGetMe = async (axiosInstance = null) => {
  const user = await (axiosInstance !== null ? axiosInstance : axios)?.post('/auth/me').then((response) => {
    if (response.status === 200) {
      if (response.data.data.user) return response.data.data.user;
    }
    return 'error';
  });
  return user;
};

async function getMe(force = false, axiosInstance = null) {
  if (me.id === -1 || force) {
    const result = await callGetMe(axiosInstance);
    if ((result as any) !== 'error') {
      me.id = result.id;
      me.active = result.active;
      me.name = result.name;
      me.email = result.email;
      me.telephone = result.telephone;
      me.avatarPath = result.avatar_path;
      me.online = result.online;
      me.organization = result.organization;
      me.checkedIn = result.checked_in;
      me.permissions = result.roles[0].permissions.map((permission: any) => permission.name) as string[];
    }
  }
  return me;
}

function useAuth() {
  function hasPermission(permName: string) {
    // TODO: separate '*' (for ideavivat) and 'dotify.*' (for super admin)

    return computed(() => {
      if (me.id === -1) return false;
      // const permArr = me.permissions.map((p: any) => p.name);
      // console.log(permArr);
      if (me.permissions.indexOf(permName.toLowerCase()) !== -1) return true;
      if (permName.indexOf('.any') !== -1) return me.permissions.find((perm) => perm.indexOf(permName.replace('.any', '')) > -1) !== undefined;
      return false;
    }).value;
  }

  // function refresh() {
  //   refetch();
  // }

  return {
    hasPermission,
    getMe,
  };
}

export default useAuth;
