
import { defineComponent, ref, computed } from 'vue';

const CONTEXT_BASE = {
  dismiss: 'ยกเลิก',
  action: 'ดำเนินการ',
  entity: 'รายการนี้',
  body: '',
};

function presetGenerator(ctx: { [key: string]: any }) {
  return {
    confirm: { // legacy for backward-compat
      actionColor: 'primary',
      title: `ยินยันการ${ctx.action}`, // modal original title
      titleBody: '', // body section big title
      titleBodyClass: '',
      body: `ยืนยันที่จะ${ctx.action}${ctx.entity}`,
    },
    'login-failed': {
      actionColor: 'primary',
      title: '',
      titleBody: 'ไม่พบผู้ใช้นี้ในระบบ',
      titleBodyClass: 'text-red-500',
      body: 'ลองตรวจสอบอีเมล/เบอร์โทรศัพท์และรหัสผ่านอีกรอบนะ',
    },
    'contact-help': {
      actionColor: 'primary',
      title: '',
      titleBody: 'พบปัญหาการใช้งาน ?',
      titleBodyClass: 'text-green-500',
      body: 'พบปัญหาการใช้งาน โปรดติดต่อ<br>เจ้าหน้าที่ ที่ดูแลท่านโดยตรง<br>หรือเบอร์โทรศัพท์ 085 933 2111',
    },
    'confirm-logout': {
      actionColor: 'secondary',
      title: '',
      titleBody: 'ยืนยันการออกจากระบบ',
      titleBodyClass: '',
      // body: 'หวังว่าจะกลับมา<br>เจอกันอีกไม่นานนะ',
    },
    'confirm-delete': {
      actionColor: 'red',
      title: '',
      titleBody: `แน่ใจนะ... ที่จะ${ctx.action || 'ลบ'}?`,
      titleBodyClass: 'text-red-500',
      body: `เป็นการ${ctx.action || 'ลบ'}จากระบบโดยถาวร<br>ไม่สามารถย้อนคืนได้`,
    },
    success: {
      actionColor: 'secondary',
      title: '',
      titleBody: `${ctx.action}${ctx.entity}แล้ว`,
      titleBodyClass: '',
      body: '',
    },
    missing: {
      actionColor: 'secondary',
      title: '',
      titleBody: `ต้อง${ctx.action}${ctx.entity}ก่อนนะ`,
      titleBodyClass: 'text-yellow-700',
      body: '',
    },
    'success-close': {
      actionColor: 'secondary',
      title: '',
      titleBody: `${ctx.action}${ctx.entity}แล้ว`,
      titleBodyClass: '',
      body: '',
    },
    'wrong-password': {
      actionColor: 'red',
      title: '',
      titleBody: 'รหัสผ่านไม่ถูกต้อง',
      titleBodyClass: 'text-red-500',
      body: 'โปรดตรวจสอบรหัสผ่านให้ถูกต้อง',
    },
    failed: {
      actionColor: 'red',
      title: '',
      titleBody: 'มีข้อผิดพลาด',
      titleBodyClass: 'text-red-500',
      body: `${ctx.body}`,
    },
    'upload-failed': {
      actionColor: 'red',
      title: '',
      titleBody: 'พื้นที่เก็บไฟล์ไม่เพียงพอ',
      titleBodyClass: 'text-red-500',
      body: 'หากท่านต้องการพื้นที่เพิ่ม<br>โปรดติดต่อ<br>เจ้าหน้าที่ ที่ดูแลท่านโดยตรง<br>หรือเบอร์โทรศัพท์ <a href="tel:085-933-2111">085 933 2111',
    },
  };
}

const CommonModalWithPreset = defineComponent({
  props: {
    type: {
      type: String,
      default: 'confirm',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const modal = ref(null);
    // ==== fake input kub ====
    const fakeInput = ref(null);

    const ctx = ref(CONTEXT_BASE);

    function compose(ctxCompose: any) {
      ctx.value = {
        ...CONTEXT_BASE,
        ...ctxCompose,
      };
      (modal.value as any).open();
      // eslint-disable-next-line
      (fakeInput.value as any)?.focus();
    }

    function secondary() {
      context.emit('secondaryDone', ctx.value);
    }

    function close() {
      if (props.type === 'success-close') context.emit('done', ctx.value);
      else context.emit('close');
      (modal.value as any).close();
    }

    function done() {
      context.emit('done', ctx.value);
      close();
    }

    const presets = computed(() => presetGenerator(ctx.value) as { [key: string]: any });

    const preset = computed(() => {
      if (props.type in presets.value) {
        return presets.value[props.type];
      }
      console.warn(`[CommonModalWithPreset] Selected type is not exist in the presets, available: ${Object.keys(presets.value)}`);
      return {};
    });

    return {
      modal,
      compose,
      close,
      presets,
      preset,
      context: ctx,
      done,
      secondary,
      // Fake Input
      fakeInput,
    };
  },
});

export default CommonModalWithPreset;
