import { AxiosInstance } from 'axios';
import { camelObjectToSnakeObject, filterValueToQuery } from '@/api/utils';

export default function address(axios: AxiosInstance) {
  async function getProvinces(payload: any) {
    const response = await axios.get(`/address/provinces?${filterValueToQuery(payload)}`);
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.error('[api/address.ts] getProvinces Error', e);
    }
    return false;
  }

  async function getDistricts(payload: any) {
    const { provinceIds } = payload;
    if (provinceIds.length === 0) return [];
    const response = await axios.get(`/address/districts?${filterValueToQuery(payload)}`);
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.error('[api/address.ts] getDistricts Error', e);
    }
    return false;
  }

  async function getSubDistricts(id: number) {
    const response = await axios.get(`/address/district/${id}`);
    try {
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.error('[api/address.ts] getSubDistricts Error', e);
    }
    return false;
  }

  return {
    getProvinces,
    getDistricts,
    getSubDistricts,
  };
}
