
import { defineComponent } from 'vue';

import { moduleList } from '@/views/report/csv/csv-report-router';

const List = defineComponent({
  setup() {
    return {
      moduleList,
    };
  },
});

export default List;
