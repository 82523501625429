import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonMultiSelectWithAxios = _resolveComponent("CommonMultiSelectWithAxios")!

  return (_openBlock(), _createBlock(_component_CommonMultiSelectWithAxios, {
    class: "common-province-selector",
    modelValue: _ctx.modelValue,
    placeholder: "เลือกจังหวัด",
    query: _ctx.getProvinces,
    searchKey: 'searchString',
    result: _ctx.getProvincesResult,
    label: _ctx.showLabel ? _ctx.label : '',
    level: _ctx.level,
    rules: _ctx.rules,
    multiple: _ctx.multiple,
    accessDisplay: 'name_in_thai',
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('update:modelValue', $event); _ctx.$emit('onProvinceChange')})
  }, {
    option: _withCtx(({ option }) => [
      _createTextVNode(_toDisplayString(_ctx.showAsEnglish ? option.name_in_english : option.name_in_thai), 1)
    ]),
    singleLabel: _withCtx(({ option }) => [
      _createTextVNode(_toDisplayString(_ctx.showAsEnglish ? option.name_in_english : option.name_in_thai), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "query", "result", "label", "level", "rules", "multiple"]))
}