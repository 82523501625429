import { useFormat } from 'vivat-next';

export default {
  types: {
    _primaryControls: {
      width: '4rem',
    },
    _increment: {
      name: 'ลำดับ',
      width: '4rem',
    },
    name: {
      name: 'ชื่อพนักงาน',
      width: '2fr',
    },
    code: {
      name: 'รหัสผู้ใช้',
      width: '8rem',
      mock: ['user1', 'user2', 'user3', 'user4'],
    },
    lastLoggedInAt: {
      name: 'เข้าใช้งานล่าสุด',
      width: '12rem',
      class: 'text-right',
      filter(val: any) {
        return useFormat.date(val).displayShortThaiDateTime();
      },
    },
  },
};
