import axios from 'axios';

import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

export const tokenName = process.env.VUE_APP_TOKEN_NAME || 'upa-fast-cloud-token';

export const baseURL = process.env.VUE_APP_BACKEND_HOST || 'https://fast-api.ideavivat-demo.com/api';

export default function useAxios() {
  const axiosInstance = axios.create({
    baseURL,
    // headers: headers
  });

  if (localStorage.getItem(tokenName)) {
    axiosInstance.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem(tokenName)}` };
  } else if (sessionStorage.getItem(tokenName)) {
    axiosInstance.defaults.headers.common = { Authorization: `Bearer ${sessionStorage.getItem(tokenName)}` };
  }

  axiosInstance.interceptors.response.use((response) => {
    if (response.status === 401) {
      console.log('status 401');
    }
    return response;
  }, (error) => {
    if ((error.message) || (error.response && error.response.data)) {
      if (error.message === 'Network Error' || error.response.data.status === 'Token is Expired' || error.response.data.status === 'Token is Invalid') {
        if (localStorage.getItem(tokenName)) {
          localStorage.removeItem(tokenName);
          localStorage.removeItem(`${tokenName}-expire`);
        }
        if (sessionStorage.getItem(tokenName)) {
          sessionStorage.removeItem(tokenName);
          sessionStorage.removeItem(`${tokenName}-expire`);
        }
        if (route.name !== 'Login') {
          console.error(error);
          router.push({ name: 'Login' });
        }
        // if (window.location.href.indexOf('Login') <= -1) window.location.href = 'login';
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  });

  const setAuthorization = async (token: string) => {
    localStorage.setItem(tokenName, token);
    axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };
  };

  const removeAuthorization = async () => {
    localStorage.removeItem(tokenName);
    localStorage.removeItem(`${tokenName}-expire`);
    axiosInstance.defaults.headers.common = { Authorization: '' };
  };

  return {
    axios: axiosInstance,
    setAuthorization,
    removeAuthorization,
  };
}
