import { AxiosInstance } from 'axios';

export default function user(axios: AxiosInstance) {
  async function createUser(payload: any) {
    return false;
  }

  async function getUser(id: number) {
    return false;
  }

  async function getUsers() {
    return false;
  }

  async function updateUser(id: number, payload: any) {
    return false;
  }

  return {
    createUser,
    getUser,
    getUsers,
    updateUser,
  };
}
