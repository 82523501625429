
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { useScreenWidth } from 'vivat-next';
import _ from 'lodash';

import { paginatorInfo, PaginatorInfo } from '@/components/CommonRichTableWithAxiosTypes';

const CommonRichTableWithAxios = defineComponent({
  props: {
    padding: { type: Boolean, default: false },
    option: { required: true },
    variables: { type: Object },
    query: { type: Function },
    result: {
      type: Function,
    },
    sort: {
      type: Object,
      default: () => ({ field: 'id', order: 'ASC' }),
    },
    sortParameterName: {
      type: String,
      required: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    refetch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context) {
    const { isMobile } = useScreenWidth();
    const loading = ref(true);
    const data = ref([]);
    const page = ref(1);
    const orderBy = ref(props.sort); // TODO: make two-way
    const searchString: Ref<undefined | string> = ref(undefined);
    const pagination: Ref<PaginatorInfo> = ref(paginatorInfo);

    const searchKey = computed(() => {
      if (!('search' in (props.option as any)) || !('column' in (props.option as any).search)) return '';
      const searchColumn = (props.option as any).search.column;
      if (searchString.value) return `search${searchColumn.charAt(0).toUpperCase()}${searchColumn.slice(1)}`;
      return '';
    });

    const query = _.debounce(async () => {
      if (!(props.query)) return '';
      loading.value = true;
      const {
        data: queryData, success, message,
      } = await props.query({
        ...(props as any).variables,
        'page[number]': page.value,
        'page[size]': props.perPage,
        orderBy: [orderBy.value],
        [searchKey.value]: searchString.value,
      });
      const { data: result, ...paginationInfo } = queryData;
      data.value = typeof props.result === 'function' ? props.result(result) : result;
      pagination.value = {
        from: queryData.from,
        to: queryData.to,
        lastPage: queryData.last_page,
        total: queryData.total,
        currentPage: queryData.current_page,
        perPage: queryData.per_page,
      };
      loading.value = false;
      if ('fetched' in context.attrs) context.emit('fetched', queryData);

      return '';
    }, 500);

    function handleSortChange(newSort: { field: string; order: string }) {
      if (props.sortParameterName) {
        orderBy.value[props.sortParameterName] = newSort.field;
        orderBy.value.order = newSort.order;
      } else orderBy.value = newSort;
    }

    function handleSearchChange(search: string) {
      if (search === '') searchString.value = undefined;
      searchString.value = search;
    }

    // bind clickRow to child when clickRow is present only
    const clickRow = computed(() => (('clickRow' in context.attrs) ? 'clickRow' : 'unusedEvent'));

    onMounted(async () => {
      await query();
    });

    watch(() => page.value, async (newVal, oldVal) => {
      if (newVal !== oldVal) await query();
    });

    watch(() => props.refetch, async (newVal) => {
      if (newVal === true) {
        await query();
        context.emit('fetching');
      }
    });

    watch(() => orderBy.value, async (newVal, oldVal) => {
      if (newVal !== oldVal) await query();
    });

    return {
      data,
      pagination,
      page,

      clickRow,
      loading,
      handleSortChange,
      handleSearchChange,
      orderBy,
      isMobile,
    };
  },
});

export default CommonRichTableWithAxios;
