import { createApp, provide, h } from 'vue';
import { plugin, useProjectTailwindColors } from 'vivat-next';
import 'vivat-next/dist/vivat.css';

import PageColumn from '@/components/vivat/PageColumn.vue';
import CommonRichTableWithAxios from '@/components/CommonRichTableWithAxios.vue';

import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import './assets/tailwind.scss';

useProjectTailwindColors(true);

createApp({
  render() {
    return h(App);
  },
})
  .component('AdvancePageColumn', PageColumn)
  .component('CommonRichTableWithAxios', CommonRichTableWithAxios)
  .use(router)
  .use(plugin)
  .mount('#app');
