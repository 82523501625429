import { AxiosInstance } from 'axios';

export default function role(axios: AxiosInstance) {
  async function createRole(payload: any) {
    return false;
  }

  async function getRole(id: number) {
    return { data: [] };
    // return false;
  }

  async function getRoles() {
    return false;
  }

  async function updateRole(id: number, payload: any) {
    return false;
  }

  async function getPermissions() {
    return false;
  }

  return {
    createRole,
    getRole,
    getRoles,
    updateRole,

    getPermissions,
  };
}
