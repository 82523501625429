import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "has-panel-children-level-0" }
const _hoisted_2 = { class: "h-10 w-10 rounded-full bg-primary-500 flex items-center justify-center" }
const _hoisted_3 = { class: "h-10 w-10 rounded-full bg-primary-500 flex items-center justify-center" }
const _hoisted_4 = {
  key: 6,
  class: "page-column-column page-column-empty-state flex-1"
}
const _hoisted_5 = {
  key: 0,
  class: "absolute top-0 right-0 text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonIcon = _resolveComponent("CommonIcon")!
  const _component_CommonScrollbarContainer = _resolveComponent("CommonScrollbarContainer")!
  const _component_CommonButton = _resolveComponent("CommonButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["page page-column bg-primary-100 flex h-full relative", {'is-mobile': _ctx.isMobile, 'is-active-as-left-sidebar': _ctx.isLeftSidebarActive, 'is-active-as-right-sidebar': _ctx.isRightSidebarActive, 'right-can-be-sidebar': _ctx.computedMobileRightPanelAsSidebar && 'right-panel' in _ctx.$slots}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "float")
    ]),
    ('left-panel' in _ctx.$slots && _ctx.computedMobileLeftPanelAsSidebar && _ctx.isMobile && !('right-panel' in _ctx.$slots))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(["shadow-lg fixed bg-primary-600 text-white z-40 h-12 flex items-center pr-1 rounded-tr-full rounded-br-full overflow-y-hidden", {'pointer-events-none opacity-0': _ctx.isLeftSidebarActive, 'opacity-100': !_ctx.isLeftSidebarActive}]),
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleLeftColumnAsSidebar && _ctx.toggleLeftColumnAsSidebar(...args))),
          style: {"left":"0","bottom":"calc(64px + env(safe-area-inset-bottom))"}
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex-1 overflow-y-hidden truncate transition-dimension duration-200", {'m-0 px-0 w-0': _ctx.isHideHintSidebarText, 'px-3': !_ctx.isHideHintSidebarText}])
          }, _toDisplayString(_ctx.computedMobileLeftPanelAsSidebarName), 3),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_CommonIcon, {
              class: "w-6 h-6 -mt-2 -mr-1",
              src: "vivat/icons/arrow-right.png",
              color: "white"
            })
          ])
        ], 2))
      : _createCommentVNode("", true),
    ('right-panel' in _ctx.$slots && _ctx.computedMobileRightPanelAsSidebar && _ctx.isMobile)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass(["shadow-lg fixed bg-white text-primary-600 z-40 h-12 flex items-center pl-1 rounded-tl-full rounded-bl-full overflow-y-hidden", {'pointer-events-none opacity-0': _ctx.isRightSidebarActive, 'opacity-100': !_ctx.isRightSidebarActive}]),
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleRightColumnAsSidebar && _ctx.toggleRightColumnAsSidebar(...args))),
          style: {"right":"0","bottom":"calc(64px + env(safe-area-inset-bottom))"}
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CommonIcon, {
              class: "w-6 h-6 -mt-2 -mr-1",
              src: "vivat/icons/arrow-right.png",
              color: "white"
            })
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["flex-1 overflow-y-hidden truncate transition-dimension duration-200", {'m-0 px-0 w-0': _ctx.isHideHintSidebarText, 'px-3': !_ctx.isHideHintSidebarText}])
          }, _toDisplayString(_ctx.computedMobileRightPanelAsSidebarName), 3)
        ], 2))
      : _createCommentVNode("", true),
    ('left-panel' in _ctx.$slots && _ctx.computedMobileLeftPanelAsSidebar && _ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["bg-black-opacity-50 fixed top-0 bottom-0 right-0 left-0 z-10 transition-opacity duration-200", {'pointer-events-none opacity-0': !_ctx.isLeftSidebarActive, 'opacity-100': _ctx.isLeftSidebarActive}]),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleLeftColumnAsSidebar && _ctx.toggleLeftColumnAsSidebar(...args)))
        }, null, 2))
      : _createCommentVNode("", true),
    ('right-panel' in _ctx.$slots && _ctx.computedMobileRightPanelAsSidebar && _ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(["bg-black-opacity-50 fixed top-0 bottom-0 right-0 left-0 z-10 transition-opacity duration-200", {'pointer-events-none opacity-0': !_ctx.isRightSidebarActive, 'opacity-100': _ctx.isRightSidebarActive}]),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleRightColumnAsSidebar && _ctx.toggleRightColumnAsSidebar(...args)))
        }, null, 2))
      : _createCommentVNode("", true),
    ('left-panel' in _ctx.$slots)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["page-column-column page-column-left-panel shadow-lg overflow-y-auto flex-shrink-0 max-h-screen max-h-screen-no-sidebar bg-primary-500 sticky top-0 left-0 bottom-0", {'has-panel-children-level-0-center-invert': _ctx.leftInvert, 'has-panel-children-level-0-center': !_ctx.leftInvert}])
        }, [
          _createVNode(_component_CommonScrollbarContainer, {
            class: _normalizeClass('h-full'),
            color: _ctx.scrollBarColor
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "left-panel")
            ]),
            _: 3
          }, 8, ["color"])
        ], 2))
      : _createCommentVNode("", true),
    ('default' in _ctx.$slots)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: _normalizeClass(["page-column-column page-column-main-panel has-panel-children-level-0", { 'lg:p-4' : _ctx.paddingMainContents,  'flex-grow flex-shrink': !_ctx.isMobile, 'pb-12': (!_ctx.isLeftSidebarActive && 'left-panel' in _ctx.$slots && _ctx.computedMobileLeftPanelAsSidebar && _ctx.isMobile) || (!_ctx.isRightSidebarActive && 'right-panel' in _ctx.$slots && _ctx.computedMobileRightPanelAsSidebar && _ctx.isMobile)}])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    (!('default' in _ctx.$slots) && !_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "empty-state-panel")
        ]))
      : _createCommentVNode("", true),
    ('right-panel' in _ctx.$slots)
      ? (_openBlock(), _createElementBlock("div", {
          key: 7,
          class: _normalizeClass(["page-column-column page-column-right-panel rounded-xl rounded-r-none bg-white shadow-lg flex-shrink-0 max-h-screen max-h-screen-no-sidebar has-panel-children-level-2 sticky top-0 right-0 bottom-0", {'can-be-sidebar': _ctx.computedMobileRightPanelAsSidebar}])
        }, [
          _createVNode(_component_CommonScrollbarContainer, {
            class: _normalizeClass('h-full'),
            color: _ctx.scrollBarColor
          }, {
            default: _withCtx(() => [
              ('onRightPanelClose' in _ctx.$attrs)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_CommonButton, {
                      small: "",
                      level: "blend",
                      icon: "vivat/icons/x.png",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('rightPanelClose')))
                    })
                  ]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "right-panel")
            ]),
            _: 3
          }, 8, ["color"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}