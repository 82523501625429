
import { defineComponent } from 'vue';

import CommonFormWithVendorEditor from '@/components/Vendor/CommonFormWithVendorEditor.vue';

const Edit = defineComponent({
  components: {
    CommonFormWithVendorEditor,
  },
  // setup() {},
});

export default Edit;
