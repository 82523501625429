
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/use/useAuth';

const Index = defineComponent({
  setup() {
    const { hasPermission } = useAuth();
    const router = useRouter();
    // if (!hasPermission('user.manage')) {
    //   router.push({
    //     name: 'Dashboard',
    //   });
    // }
  },
});

export default Index;
