export interface PaginatorInfo {
  from: number;
  to: number;
  lastPage: number;
  total: number;
  currentPage: number;
  perPage: number;
  prevPageUrl?: string | null;
  firstPageUrl?: string | null;
  nextPageUrl?: string | null;
  lastPageUrl?: string | null;
}

export const paginatorInfo: PaginatorInfo = {
  from: 0,
  to: 0,
  lastPage: 1,
  total: 0,
  currentPage: 1,
  perPage: 15,
  prevPageUrl: null,
  firstPageUrl: null,
  nextPageUrl: null,
  lastPageUrl: null,
};
